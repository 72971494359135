import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["payDetails"]

  connect() {
    const dataset = this.element.dataset
    this.fetchPaymentConfirm(dataset.paymentDetailsUrl)
  }

  fetchPaymentConfirm(uri) {
    fetch(uri, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) throw response
        return response.json()
      })
      .then((data) => {
        this.payDetailsTarget.innerHTML = data.content
      })
      .catch((error) => console.error(error))
  }
}
