import { Controller } from 'stimulus'
import renderError from 'helpers/errors_handler'
import feather from 'feather-icons'
import callSelect2Modal from '../vendor/select2'

export default class extends Controller {
  static targets = ['form', 'formWrapper', 'listWrapper']

  onFiltered(event) {
    let [data, status, xhr] = event.detail
    $(this.listWrapperTarget).html(xhr.response)
    feather.replace()
  }

  onNew(event) {
    let [data, status, xhr] = event.detail
    $('.modal').remove()
    const $modal = $(xhr.response)

    $(this.element).append($modal)
    callSelect2Modal()
    $modal.modal('show')
  }

  onEdit(event) {
    let [data, status, xhr] = event.detail
    $('.modal').remove()
    const $modal = $(xhr.response)

    $(this.element).append($modal)
    callSelect2Modal()
    $modal.modal('show')
  }

  onShow(event) {
    let [data, status, xhr] = event.detail
    $('.modal').remove()
    const $modal = $(xhr.response)
    
    $(this.element).append($modal)
    $modal.modal('show')
  }

  onCreated(event) {
    let [data, status, xhr] = event.detail
    $(this.listWrapperTarget).html(xhr.response)
    this._modalHide()
    feather.replace()
  }
  
  onUpdated(event) {
    let [data, status, xhr] = event.detail
    const $resource = $(xhr.response).attr('id')
    $(`#${$resource}`).replaceWith(xhr.response)
    this._modalHide()
  }
  
  onDestroy(event) {
    let [data, status, xhr] = event.detail
    $(this.listWrapperTarget).html(xhr.response)
    feather.replace()
  }

  onError(event) {
    const $form = $(this.formTarget)
    let [data, status, xhr] = event.detail
    let errors = JSON.parse(xhr.response)

    renderError($form, errors.model, errors.errors)
  }

  // private
  _modalHide() {
    $('.modal').modal('hide')
  }
}
