import React, { Fragment, useState, useEffect} from 'react'
import { DateRangePicker, toMomentObject } from 'react-dates'

const DateRangeInput = (props) => {
  const {
    isCompulsory,
    type,
    options
  } = props.item
  const startDateName = `${props.startDateName || type}_start_date`
  const endDateName = `${props.endDateName || type}_end_date`

  const [error, setError] = useState({})
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [focusedInput, setFocusedInput] = useState(null)

  // Validasi startDate dan endDate saat range change
  useEffect(() => {
    let currentError = {}

    if (isCompulsory && (startDate === null || endDate === null)) {
      currentError = { message: 'Invalid range' }
    }

    setError(currentError)
  }, [startDate, endDate])

  useEffect(() => {
    setError(props.error || {})
  }, [])

  return(
    <Fragment>
      <DateRangePicker
        startDatePlaceholderText={options.startDateHelpMessage}
        endDatePlaceholderText={options.endDateHelpMessage}
        onDatesChange={({ startDate, endDate }) => {
          setStartDate(startDate)
          setEndDate(endDate)
        }}
        startDate={toMomentObject(startDate)}
        startDateId={startDateName}
        endDate={toMomentObject(endDate)}
        endDateId={endDateName}
        focusedInput={focusedInput}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        block={true}
        hideKeyboardShortcutsPanel={true}
      />
      <div className='text-danger mt-2'>{error.message}</div>
    </Fragment>
  )
}

export default DateRangeInput