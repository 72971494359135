import { DirectUpload } from "@rails/activestorage";

class Uploader {
  constructor(file, source) {
    this.file = file;
    this.source = source;
    this.upload = new DirectUpload(this.file, this.source.url, this);
  }

  start(errorCallback, successCallback) {
    this.source.ref.classList.remove("d-none");
    this.upload.create((error, blob) => {
      setTimeout(() => {
        this.source.ref.classList.add("d-none");

        error ? errorCallback(error) : successCallback(blob);

        const progressElement = this.source.ref.querySelector("#direct-upload-progress");
        this.setProgressValue(progressElement, 0);
      }, 700);
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => this.directUploadDidProgress(event));
  }

  directUploadDidProgress(event) {
    const progress = ((event.loaded / event.total) * 100).toFixed(1);
    const progressElement = this.source.ref.querySelector("#direct-upload-progress");
    this.setProgressValue(progressElement, progress);
  }

  setProgressValue(progressElement, value = 0) {
    progressElement.style.width = `${value}%`;
    progressElement.innerText = `${value}%`;
    progressElement.setAttribute("aria-valuenow", value);
  }
}

export default Uploader;
