import { Controller } from 'stimulus'
import renderError from 'helpers/errors_handler'

export default class extends Controller {
  static targets = ['form', 'formWrapper']

  onNew(event) {
    let [data, status, xhr] = event.detail
    const $modal = $(xhr.response)

    $(this.element).append($modal)
    $modal.modal('show')
  }

  onEdit(event) {
    let [data, status, xhr] = event.detail
    const $modal = $(xhr.response)

    $(this.element).append($modal)
    $modal.modal('show')
  }

  onShow(event) {
    let [data, status, xhr] = event.detail
    const $modal = $(xhr.response)
    
    $(this.element).append($modal)
    $modal.modal('show')
  }

  onCreated(event) {
    let [data, status, xhr] = event.detail
    const $stock = $(xhr.response).attr('id')
    $(`#${$stock}`).replaceWith(xhr.response)
    this._modalHide()
  }

  onUpdated(event) {
    let [data, status, xhr] = event.detail
    const $stock = $(xhr.response).attr('id')
    $(`#${$stock}`).replaceWith(xhr.response)
    this._modalHide()
  }

  onError(event) {
    const $form = $(this.formTarget)
    let [data, status, xhr] = event.detail
    let errors = JSON.parse(xhr.response)

    renderError($form, errors.model, errors.errors)
  }

  // private
  _modalHide() {
    $('.modal').modal('hide')
  }
}
