import "select2"
import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const path = this.data.get("path")
    const query = this.data.get("query")
    const placeholder = this.data.get("placeholder")
    const text = this.data.get("text")

    $(this.element).select2({
      minimumResultsForSearch: 5,
      minimumInputLength: 3,
      width: "100%",
      placeholder: placeholder,
      theme: "bootstrap4",
      ajax: {
        url: path,
        type: "GET",
        dataType: "json",
        data: (params) => {
          return { [query]: params.term }
        },
        processResults: (data) => {
          return {
            results: $.map(data.data, (item) => {
              return { text: item[text], id: item.id }
            }),
          }
        },
      },
    })

    $(this.element).on("select2:select", function () {
      let event = new Event("change", { currentTarget: this.element })
      this.dispatchEvent(event)
    })
  }
}
