import { Controller } from "stimulus"

export default class extends Controller {
  loginError(event) {
    this._removeAllError()

    const [data] = event.detail
    const form = document.getElementById("login-form")
    const msg = form.querySelector("#login-error-message")
    const inputs = form.querySelectorAll("input.form-control")

    msg.classList.remove("d-none")
    msg.innerHTML = data.message

    inputs.forEach(input => {
      input.classList.add("is-invalid")
    })

    setTimeout(() => { msg.classList.add("d-none") }, 3000)

    $("#authModal").one("hidden.bs.modal", () => {
      msg.classList.add("d-none")
      msg.innerHTML = ""

      inputs.forEach(input => {
        input.value = ""
        input.classList.remove("is-invalid")
      })
    })
  }

  signupError(event) {
    this._removeAllError()

    const [data] = event.detail
    const form = document.getElementById("signup-form")

    Object.keys(data.message).forEach(key => {
      const input = form.querySelector(`input[name="customer[${key}]"]`)
      input.classList.add("is-invalid")
      this._signupErrorMessage(input, data.message[key])
    })

    $("#authModal").one("hidden.bs.modal", () => {
      form.querySelectorAll(".text-danger").forEach(danger => danger.remove())
      form.querySelectorAll("input").forEach(input => {
        input.value = ""
        input.classList.remove("is-invalid")
      })
    })
  }

  // private

  _removeAllError() {
    document.querySelectorAll(".text-danger").forEach(danger => danger.remove())
    document.querySelectorAll(".is-invalid").forEach(invalid => {
      invalid.classList.remove("is-invalid")
    })
  }

  _signupErrorMessage(el, msg) {
    el.insertAdjacentHTML(
      "afterend",
      `<span class="text-danger">${msg}</span>`
    )
  }
}
