import React, { Fragment, useState} from 'react'

const Text = (props) => {
  const {
    isCompulsory,
    type,
    helpMessage
  } = props.item
  const name = props.name || type
  const id = props.id || name

  const [error, setError] = useState(props.error || {})
  const hasError = Object.keys(error).length > 0
  const errorClassName = hasError && 'is-invalid'

  const handleInputChange = (e) => {
    let currentError = {}

    if (isCompulsory && e.target.value === '') {
      currentError = { message: "Input can't be blank" }
    }

    setError(currentError)
  }

  return(
    <Fragment>
      <input type='text' placeholder={helpMessage} className={`form-control ${errorClassName}`} name={name} id={id} onChange={handleInputChange} />
      <div className='text-danger mt-2'>{error.message}</div>
    </Fragment>
  )
}

export default Text