//
// popover.js
//

'use strict';

// $(function() {
//   $('[data-toggle="popover"]').popover();
// });

document.addEventListener('turbolinks:load', () => {
  $('[data-toggle="popover"]').popover();
})