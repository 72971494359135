import React from 'react'
import { Edit } from 'react-feather'
import Text from '../InputTypes/Text'
import Number from '../InputTypes/Number'
import DateInput from '../InputTypes/DateInput'
import DateRangeInput from '../InputTypes/DateRangeInput'
import Paragraph from '../InputTypes/Paragraph'
import ListSingleAnswer from '../InputTypes/ListSingleAnswer'
import ListMultipleAnswer from '../InputTypes/ListMultipleAnswer'

function ItemPreview(props) {
  const { name, editItem, item } = props
  const options = item.options || {}

  const inputOptions = Object.keys(options).map((key, i) =>
    <input key={i} type='hidden' name={`${name}[][options][${key}]`} value={eval(`options.${key}`)} readOnly={true} />
  )

  return (
    <li key={item.id} className='list-group-item'>
      <input type='hidden' name={`${name}[][id]`} value={item.id} readOnly={true} />
      <input type='hidden' name={`${name}[][label]`} value={item.label} readOnly={true} />
      <input type='hidden' name={`${name}[][type]`} value={item.type} readOnly={true} />
      <input type='hidden' name={`${name}[][helpMessage]`} value={item.helpMessage} readOnly={true} />
      <input type='hidden' name={`${name}[][isCompulsory]`} value={item.isCompulsory} readOnly={true} />
      {inputOptions}
      <div className='form-group row m-0'>
        <label className={`col-4 m-0 ${item.isCompulsory && 'required'}`}>{item.label}</label>
        <div className='col-7'>
          {item.type === 'text' && <Text item={item}/>}
          {item.type === 'date' && <DateInput item={item}/>}
          {item.type === 'date_range' && <DateRangeInput item={item}/>}
          {item.type === 'number' && <Number item={item}/>}
          {item.type === 'paragraph' && <Paragraph item={item}/>}
          {item.type === 'list_with_single_answer' && <ListSingleAnswer item={item}/>}
          {item.type === 'list_with_multiple_answer' && <ListMultipleAnswer item={item}/>}
        </div>
        <div className='col-1 text-right'>
          <button type='button' className='btn btn-link p-0' onClick={() => editItem(item)}>
            <Edit size='14'/>
          </button>
        </div>
      </div>
    </li>
  )
}

export default ItemPreview