import { Controller } from "stimulus";
import { convertPrices } from "../../helpers/convert_price";

const NOT_SUPPORTED_BROWSER = "Your browser is not support for localStorage, please update."

export default class extends Controller {
  static targets = ["selectedCurrencyLabel", "currencies"];

  static values = {
    defaultCurrency: String,
    defaultRate: Number,
  }

  initialize() {
    this.defaultCurrency = {
      currency: this.defaultCurrencyValue,
      rate: this.defaultRateValue,
    };

    this._initializeCurrencyStorage();
    convertPrices();
  }

  onCurrencyChange(event) {
    event.preventDefault();

    const currencyObj = {
      currency: event.currentTarget.innerHTML,
      rate: event.currentTarget.dataset.closingRate,
    };

    this._setCurrencyStorage(currencyObj);
    this._updateCurrencySelector();
    convertPrices();
  }

  _isSupportLocalStorage() {
    return typeof window.localStorage !== undefined;
  }

  _hasCurrencyStorage() {
    return localStorage.selected_currency !== undefined;
  }

  _initializeCurrencyStorage() {
    if (this._isSupportLocalStorage()) {
      if (this._hasCurrencyStorage()) {
        const existingCurrencies = this.currenciesTargets.map((element) =>
          element.id.replace("_currency", "")
        );

        if (!existingCurrencies.includes(this._getCurrencyStorage().currency)) {
          this._setCurrencyStorage(this.defaultCurrency);
        }
      } else {
        this._setCurrencyStorage(this.defaultCurrency);
      }

      this._updateCurrencySelector();
    } else {
      console.error(NOT_SUPPORTED_BROWSER);
      alert(NOT_SUPPORTED_BROWSER);
    }
  }

  _updateCurrencySelector() {
    const currency = this._getCurrencyStorage().currency;
    this.currenciesTargets.forEach((element) => element.classList.remove("d-none"));
    this.selectedCurrencyLabelTarget.innerHTML = currency;
    document.getElementById(`${currency}_currency`).classList.add("d-none");
  }

  _setCurrencyStorage(obj) {
    localStorage.setItem("selected_currency", JSON.stringify(obj));
  }

  _getCurrencyStorage() {
    return JSON.parse(localStorage.selected_currency);
  }
}
