//
// theme.js
// Theme JavaScript
//

'use strict';

import './card'
import './collapse'
import './countdown'
import './dropdown'
// import './flickity'
import './form'
import './highlight'
import './img-comp'
import './list'
import './map'
import './navbar'
import './popover'
import './rating'
import './simplebar'
import './smooth-scroll'
import './tooltip'
import './vote'