import { Controller } from "stimulus";
import { convertPrices } from "../../helpers/convert_price";

export default class extends Controller {
  static targets = ["shippingCheckbox", "newShippingAddress"];
  static values = {
    providerUrl: String,
    defaultParams: Object,
  };

  connect() {
    this._retrieveShippingByShippingID();
  }

  _skipRetrieveData() {
    return (
      this.shippingCheckboxTarget.checked &&
      (this.defaultParamsValue.city == "" ||
        this.defaultParamsValue.country_code == "" ||
        this.defaultParamsValue.zip_code == "")
    );
  }

  handleShippingField() {
    this.defaultParamsValue = {
      ...this.defaultParamsValue,
      city: this.newShippingAddressTarget.querySelector('[name="checkout[city]"]').value,
      country_code: this.newShippingAddressTarget.querySelector('[name="checkout[country_code]"]').value,
      zip_code: this.newShippingAddressTarget.querySelector('[name="checkout[zip_code]"]').value,
    };

    if (
      this.defaultParamsValue.city != "" &&
      this.defaultParamsValue.country_code != "" &&
      this.defaultParamsValue.zip_code != ""
    ) {
      this._fetchShippingProvider();
    }
  }

  changeShippingAddress() {
    const existingAddress = document.querySelector("[name='checkout[shipping_address_id]']");
    const newAddressFields = this.newShippingAddressTarget.querySelectorAll(
      "select, input:not([name*='checkout[address_optional]'])"
    );

    existingAddress.disabled = this.shippingCheckboxTarget.checked;
    this._toggleNewShippingAddressField(newAddressFields, this.shippingCheckboxTarget.checked);

    if (this.shippingCheckboxTarget.checked) {
      this.shippingProviderTable.innerHTML = this._alertMessage(
        "Add your valid address to showing all available shipping options"
      );
    } else {
      this._retrieveShippingByShippingID();
    }
  }

  // private

  _retrieveShippingByShippingID() {
    if (this.shippingCheckboxTarget.checked) return;

    const el = document.querySelector('[name="checkout[shipping_address_id]"]');

    if (el.value) {
      this.defaultParamsValue = { ...this.defaultParamsValue, id: el.value };
      this._fetchShippingProvider();
    }
  }

  _toggleNewShippingAddressField(fields, checked = true) {
    fields.forEach((field) => {
      field.required = checked;
      field.disabled = !checked;

      if (!checked) field.value = "";
    });
  }

  async _fetchShippingProvider() {
    this.shippingProviderTable.innerHTML = this._shippingSpinner();

    const body = JSON.stringify({ shipping: this.defaultParamsValue });
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": this.token,
    };

    try {
      const response = await fetch(this.providerUrlValue, { method: "POST", headers: headers, body: body });

      if (!response.ok) throw Error("Invalid request");

      const jsonData = await response.json();

      if (!this._skipRetrieveData()) {
        this.shippingProviderTable.innerHTML = jsonData.content;
      }
      convertPrices();
    } catch {
      this.shippingProviderTable.innerHTML = this._alertMessage();
    }

    this._resetDefaultParams();
    this._removeShippingSpinner();
  }

  _resetDefaultParams() {
    this.defaultParamsValue = { ...this.defaultParamsValue, id: "", city: "", country_code: "", zip_code: "" };
  }

  _alertMessage(msg = null) {
    let message = "Something went wrong when request rate with your address. Please, check you valid address";
    if (msg) message = msg;

    return `<div class="alert alert-warning"><span>${message}</span></div>`;
  }

  _shippingSpinner() {
    return `
      <div id="shipping-provider-loader" class="p-4 text-center">
        <div class="spinner-border text-primary" role="status" style="width: 3rem; height: 3rem;"></div>
        <div class="mt-2"><span class="text-muted">Loading shipping table</span></div>
      </div>
    `;
  }

  _removeShippingSpinner() {
    document.getElementById("shipping-provider-loader")?.remove();
  }

  get token() {
    return document.querySelector('meta[name="csrf-token"]').content;
  }

  get shippingProviderTable() {
    return document.getElementById("shipping-provider-list");
  }
}
