import React, { Component } from 'react'

class Button extends Component {
  render () {
    return (
      <button className='btn btn-light' onClick={this.props.onStatusChange}>change status</button>
    );
  }
}

export default Button
