import React, { Component } from 'react'

class Input extends Component {
  render () {
    return (
      <input type='text' name='nama' value={this.props.item.name} onChange={this.props.onValueChange} />
    );
  }
}

export default Input