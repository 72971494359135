import { Controller } from "stimulus"
import { convertPrices } from "../helpers/convert_price"

export default class extends Controller {
  addToCart({ detail }) {
    const [data] = detail
    const element = document.getElementById("shopping-cart")
    const modal = $("#add-to-cart-notification")

    modal.html(data.content)
    modal.find(".modal").modal("show")
    element.firstElementChild.dataset.cartItems = data.total

    convertPrices()
  }
}
