import { Controller } from "stimulus";
import { convertPrice } from "../helpers/convert_price";

export default class extends Controller {
  static targets = ["ccPrice", "shippingPrice", "totalPrice"];

  onError(event) {
    console.log(event);
  }

  selectShipping({ currentTarget }) {
    const hiddenInputs = document.querySelectorAll('[name="checkout[provider]"]');
    hiddenInputs.forEach((input) => (input.disabled = true));

    const currentHidden = document.querySelector(`#hidden-${currentTarget.id}`);
    if (currentHidden) currentHidden.disabled = false;

    const providerDetail = currentTarget.closest(".provider-detail");
    const shippingPriceEl = providerDetail.querySelector("[data-price-tag]");
    const price = shippingPriceEl.dataset?.priceTag || 0;

    // set shipping price
    this.shippingPriceTarget.dataset.priceTag = price;
    this.shippingPriceTarget.innerHTML = convertPrice(price);

    // count total price with selected shipping price
    let defaultPrice = this.totalPriceTarget.dataset.totalPrice;

    if (price == 0) {
      this.ccPriceTarget.dataset.priceTag = defaultPrice;
      this.totalPriceTarget.dataset.priceTag = defaultPrice;
      this.ccPriceTarget.innerHTML = convertPrice(defaultPrice);
      this.totalPriceTarget.innerHTML = convertPrice(defaultPrice);
    } else {
      let total = parseFloat(defaultPrice) + parseFloat(price);

      this.ccPriceTarget.dataset.priceTag = total;
      this.totalPriceTarget.dataset.priceTag = total;
      this.ccPriceTarget.innerHTML = convertPrice(total);
      this.totalPriceTarget.innerHTML = convertPrice(total);
    }
  }
}
