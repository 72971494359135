// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Styles
import "styles/theme.scss"

// Images
require.context("../images", true)

// Vendor
require("vendor/react-rails")
require("vendor/feather-icons")
require("vendor/jquery")
require("vendor/fancybox")
require("vendor/bootstrap")
require("vendor/highlightjs")
require("vendor/jarallax")
require("vendor/list")
require("vendor/simplebar")
require("vendor/smooth-scroll")
require("vendor/shopper/theme")
require("vendor/cropperjs")
require("vendor/slick-carousel")
require("vendor/select2")
require("vendor/bootstrap3-typeahead")

// Stimulus
import "controllers"

require("trix")
require("@rails/actiontext")