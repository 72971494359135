import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formFilter", "clearButton", "orders"]

  connect() {
    this.typingTimer
  }

  filterByCustomerName() {
    clearTimeout(this.typingTimer)
    this.typingTimer = setTimeout(() => {
      this.filterOrder()
    }, 5000)
  }

  filterByOrderDate() {
    this.filterOrder()
  }

  filterByShippingDate() {
    this.filterOrder()
  }

  filterByStatus() {
    this.filterOrder()
  }

  // Private

  filterOrder() {
    this.ordersTarget.innerHTML = this.spinner()

    const filterInput = this.formFilterTarget.querySelectorAll('[id^="orders_"]')
    let queryParams = {}

    filterInput.forEach((input) => {
      if (input.value !== "") queryParams[input.name] = input.value
    })

    this.fetchOrder(queryParams)
  }

  fetchOrder(query) {
    const queryParams = Object.keys(query)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
      })
      .join("&")

    fetch(`/merchant/orders?${queryParams}`, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "ContentType": "application/json"
      }
    })
      .then((response) => {
        if (!response.ok) throw response
        return response.json()
      })
      .then((data) => {
        this.ordersTarget.innerHTML = data.content
        this.clearButtonTarget.classList.remove("d-none")
      })
      .catch((error) => console.error(error))
  }

  spinner() {
    return `
      <div id="spinner" class="mt-5 text-center">
        <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem;">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="mt-3 text-muted">Loading...</div>
      </div>
    `
  }
}
