import React, { Fragment, useState, useEffect } from 'react'
import { SingleDatePicker, toMomentObject } from 'react-dates'

const Date = (props) => {
  const {
    isCompulsory,
    type,
    helpMessage
  } = props.item
  const name = props.name || type

  const [error, setError] = useState({})
  const [date, setDate] = useState(null)
  const [focusedInput, setFocusedInput] = useState(false)

  useEffect(() => {
    let currentError = {}

    if (isCompulsory && date === null) {
      currentError = { message: "Invalid date" }
    }

    setError(currentError)
  }, [date])

  useEffect(() => {
    setError(props.error || {})
  }, [])

  return(
    <Fragment>
      <SingleDatePicker
        placeholder={helpMessage}
        date={toMomentObject(date)}
        onDateChange={date => setDate(date)}
        focused={focusedInput}
        onFocusChange={({ focused }) => setFocusedInput(focused)}
        id={name}
        block={true}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel={true}
      />
      <div className='text-danger mt-2'>{error.message}</div>
    </Fragment>
  )
}

export default Date