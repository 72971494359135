import 'select2'
import 'select2/dist/css/select2.css'

$(document).on('turbolinks:load', () => {
  $('.select2-select').select2({
    minimumInputLength: 1,
    width: '100%',
    tags: true,
    dropdownParent: $('.select2-modal')
  })
})

export default function callSelect2Modal() {
  $('.select2-modal').select2({
    dropdownParent: $('#modal_form')
  })
}
