import { Controller } from "stimulus"

export default class extends Controller {
  addWishlist(event) {
    event.preventDefault()

    const token = document.querySelector('meta[name="csrf-token"]').content
    const productId = document.querySelector("[name='cart[cart_product][product_id]']").value

    fetch("/wishlists", {
      method: "POST",
      headers: {
        "X-CSRF-Token": token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ wishlist: { product_id: productId } }),
    })
      .then((response) => {
        if (!response.ok) throw response
        return response.text()
      })
      .then(() => this.popover("The product has been added to Wishlists"))
      .catch(() => this.popover("The product can't added to Wishlits"))
  }

  popover(content = "Hello World") {
    const $navWishlist = $("#nav-wishlists")

    $navWishlist.popover({
      trigger: "focus",
      placement: "bottom",
      content: content,
    })

    $navWishlist.popover("show")

    $(document).click((e) => {
      $navWishlist.not(this).popover("hide")
    })
  }
}
