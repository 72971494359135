import React, { Fragment } from 'react'

const ListMultipleAnswer = (props) => {
  const {
    type,
    options
  } = props.item
  const name = props.name || type

  return(
    <Fragment>
      {options.answerList.split('\n').map((list, i) =>
        <div className='custom-control custom-checkbox' key={i}>
          <input type='checkbox' name={name} className='custom-control-input' value={list} id={`${name}_${list}`} />
          <label className='custom-control-label' htmlFor={`${name}_${list}`}>{list}</label>
        </div>
      )}
    </Fragment>
  )
}

export default ListMultipleAnswer