import React, { Fragment, useState, useEffect } from "react"
import PriceCalculator from "./price_calculator"

const InputPricing = ({ name, prefix, object, permission }) => {
  const [priceRef, setPriceRef] = useState(null)
  const [timer, setTimer] = useState(0)
  const [loading, setLoading] = useState(false)

  const calculateSellingPrice = (value) => {
    if (!permission) return
    setLoading(true)
    clearTimeout(timer)

    const newTimer = setTimeout(() => {
      PriceCalculator(parseInt(value)).then(result => {
        setPriceRef(result)
        setLoading(false)
      })
    }, 3500)

    setTimer(newTimer)
  }

  useEffect(() => {
    if (object.price) {
      PriceCalculator(parseInt(object.price)).then(result => setPriceRef(result))
    }
  }, [])

  return (
    <Fragment>
      <div className="form-group row align-items-center">
        <div className="col-12 col-md-2">
          <label className="required" htmlFor={`${prefix}_price`}>
            Nett Price
          </label>
        </div>
        <div className="col-12 col-md-10">
          <input
            type="number"
            className="form-control"
            name={`${name}[price]`}
            id={`${prefix}_price`}
            defaultValue={object.price}
            onChange={(e) => calculateSellingPrice(e.currentTarget.value)}
          />
        </div>
      </div>
      {permission && (
        <div className="form-group row align-items-center">
          <div className="col-12 col-md-2">
            <label className="required" htmlFor={`${prefix}_selling_price`}>
              Selling Price
            </label>
          </div>
          <div className="col-12 col-md-10">
            {loading && <span className="text-muted">Calculating selling price...</span>}

            {!loading && (
              <div id="price-suggestion">
                <input
                  type="number"
                  className="form-control mb-2"
                  name={`${name}[selling_price]`}
                  id={`${prefix}_selling_price`}
                  defaultValue={priceRef || object.sellingPrice}
                />
                {priceRef && (
                  <span className="ml-1 text-muted">
                    Suggestion: {priceRef}
                  </span>
                )}
                {!priceRef && (
                  <span className="ml-1 text-muted">
                    Price suggestion is not available for this range. Add rule for this range here
                    <a href="/merchant/price_calculators" className="ml-1" target="_blank">[link]</a>
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <div className="form-group row align-items-center">
        <div className="col-12 col-md-2">
          <label className="required" htmlFor={`${prefix}_min_qty`}>
            Minimum Quantity
          </label>
        </div>
        <div className="col-12 col-md-10">
          <input
            type="number"
            className="form-control"
            name={`${name}[min_qty]`}
            id={`${prefix}_min_qty`}
            defaultValue={object.minQty}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default InputPricing
