import React, { Fragment, useState } from 'react'
import Button from './HelloWorld/Button';
import Input from './HelloWorld/Input';

// class HelloWorld extends Component {
//   state = {
//     items: [
//       {
//         id: 1,
//         name: 'Wahyu',
//         status: 'single'
//       },
//       {
//         id: 2,
//         name: 'Nanok',
//         status: 'married'
//       },
//       {
//         id: 3,
//         name: 'Dodok',
//         status: 'single'
//       },
//     ]
//   }

//   handleStatusChange(id) {
//     event.preventDefault()
//     const items = this.state.items

//     items.map(item => {
//       if (item.id === id) {
//         if (item.status === 'married') {
//           item.status = 'single'
//         } else {
//           item.status = 'married'
//         }
//       }
//     })

//     this.setState({ items })
//   }

//   handleInputChange(id) {
//     const items = this.state.items

//     items.map(item => {
//       if (item.id === id) {
//         item.name = event.target.value
//       }
//     })

//     this.setState({ items })
//   }

//   render () {
//     return (
//       <Fragment>
//         <ul className='list-group'>
//         {this.state.items.map(item =>
//           <li key={item.id} className='list-group-item'>
//             {item.name} - {item.status}
//             <Button onStatusChange={() => this.handleStatusChange(item.id)}>change status</Button>
//             <Input onValueChange={() => this.handleInputChange(item.id)} item={item} />
//           </li>
//         )}
//         </ul>
//       </Fragment>
//     );
//   }
// }

function HelloWorld() {
  const itemsData = [
    {
      id: 1,
      name: 'Wahyu',
      status: 'single'
    },
    {
      id: 2,
      name: 'Nanok',
      status: 'married'
    },
    {
      id: 3,
      name: 'Dodok',
      status: 'single'
    },
  ]

  const [items, setItems] = useState(itemsData)

  const handleStatusChange = (id) => {
    event.preventDefault()

    items.map(item => {
      if (item.id === id) {
        item.status = item.status === 'married' ? 'single' : 'married'
      }
    })

    setItems([...items])
  }

  return (
    <Fragment>
      <ul className='list-group'>
      {items.map(item =>
        <li key={item.id} className='list-group-item'>
          {item.name} - {item.status}
          <Button onStatusChange={() => handleStatusChange(item.id, )}>change status</Button>
        </li>
      )}
      </ul>
    </Fragment>
  )
}

export default HelloWorld
