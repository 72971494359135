import { Controller } from "stimulus"

export default class extends Controller {
  onSuccess(event) {
    const [data, status, xhr] = event.detail

    if (status == "OK") {
      localStorage.removeItem("grouped-product")
      const namespace = xhr.responseURL.includes('supplier') ? 'supplier' : 'merchant'
      Turbolinks.visit(`/${namespace}/products`)
    } else {
      console.log("Hello World")
    }
  }
}
