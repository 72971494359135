const PriceCalculator = async(price) => {
  const uri = `/merchant/products/price_calculators?price=${price}`
  const response = await fetch(uri, {
    method: 'GET',
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    }
  })

  if (response.ok) {
    const data = await response.json()
    return data.price
  } else {
    console.error(response)
    return null
  }
}

export default PriceCalculator
