import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["buttonNew", "buttonAdd"]

  connect() {
    this.toggleBtn()
  }

  toggleBtn() {
    if (this.products.length > 0) {
      this.buttonNewTarget.classList.remove("d-none")
      this.buttonAddTarget.classList.remove("d-none")
    } else {
      this.buttonNewTarget.classList.add("d-none")
      this.buttonAddTarget.classList.add("d-none")
    }
  }

  onNew(event) {
    const [data, status, xhr] = event.detail
    const el = document.getElementById("group_modal")
    el.innerHTML = xhr.response

    const form = el.querySelector("form")
    this.products.forEach((code) => {
      form.insertAdjacentHTML(
        "beforeend",
        `<input type="hidden" value="${code}" name="product[product_codes][]">`
      )
    })

    $(el.querySelector(".modal")).modal("show")
  }

  get products() {
    return JSON.parse(localStorage.getItem("grouped-product")) || []
  }
}
