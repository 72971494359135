import { Controller } from 'stimulus'
import feather from 'feather-icons'
import { starSelection } from '../../vendor/shopper/rating'
import { initVote } from '../../vendor/shopper/vote'

export default class extends Controller {
  static targets = ['listWrapper']

  onFiltered(event) {
    this._render(event)
  }

  onCreate(event) {
    this._render(event)
  }

  onUpdate(event) {
  }

  onError(event) {
    console.log('Error')
  }

  // private
  _render(event) {
    let [data, status, xhr] = event.detail
    $(this.listWrapperTarget).html(xhr.response)
    feather.replace()
    starSelection()
    initVote()
  }
}