import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list"]

  onSearch(event) {
    const query = event.currentTarget.value
    const rows = this.listTarget.rows

    rows.forEach((row) => {
      const regex = new RegExp(query, "gi")
      const tdCode = row.cells[0].innerHTML
      const tdCustomerName = row.cells[1].innerHTML
      const td = tdCode.concat(" ", tdCustomerName)

      if (!td.match(regex)) {
        row.classList.add("d-none")
      } else {
        row.classList.remove("d-none")
      }
    })
  }
}
