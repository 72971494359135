import React, { useState, Fragment } from 'react'
import { Plus, Check, X } from 'react-feather'
import Number from '../InputTypes/Number'

function ItemForm(props) {
  const formTypes = ['text', 'number', 'date', 'date_range', 'list_with_single_answer', 'list_with_multiple_answer', 'paragraph']

  const [item, setItem] = useState(props.item)
  const itemOptions = item.options || {}

  const errors = props.errors
  const errorKeys = Object.keys(errors)

  const handleInputChange = (event) => {
    let value = event.target.value
    let options = itemOptions
    const name = event.target.name

    value = name === 'isCompulsory' ? event.target.checked : value

    if (name === 'type') {
      if (value === 'number') {
        options = {displayAs: 'input_box', unit: ''}
      } else if (value === 'list_with_single_answer') {
        options = {displayAs: 'radio', answerList: ''}
      } else if (value === 'list_with_multiple_answer') {
        options = {answerList: ''}
      }
    }

    setItem({ ...item, [name]: value, options })
  }

  const handleInputOptionsChange = (event) => {
    const {name, value} = event.target
    setItem({ ...item, options: {...itemOptions, [name]: value}})
  }

  const handleInputOptionsChangeRemote = (inputValue) => {
    setItem({ ...item, options: {...itemOptions, [inputValue.name]: inputValue.value}})
  }

  const inputNumberDefaultProps = {
    type: 'number',
    helpMessage:'Minimum Number',
    isCompulsory: true,
    options: {
      displayAs: 'input_box_with_control',
      interval: 1
    }
  }

  const handleErrorField = (field) => {
    let currentError
    if (errorKeys.includes(field) && errorKeys.length > 0) {
      currentError = { className: 'is-invalid', isError: true, message: errors[field] }
    } else {
      currentError = {}
    }

    return currentError
  }

  const errorMessage = (field) => {
    return handleErrorField(field).isError && (
      <div className='text-danger mt-2'>{handleErrorField(field).message}</div>
    )
  }

  const numberOptions = (
    <Fragment>
      <hr/>
      <div className='form-group row'>
        <div className='col-6'>
          <label>Min</label>
          <Number item={inputNumberDefaultProps} value={itemOptions.min || 0} name='min' handleInputOptionsChange={handleInputOptionsChangeRemote} />
        </div>
        <div className='col-6'>
          <label>Max</label>
          <Number item={inputNumberDefaultProps} value={itemOptions.max || 0} name='max' handleInputOptionsChange={handleInputOptionsChangeRemote} />
        </div>
      </div>
      <div className='form-group row'>
        <div className='col-6'>
          <label>Interval</label>
          <Number item={inputNumberDefaultProps} value={itemOptions.interval || 0} name='interval' handleInputOptionsChange={handleInputOptionsChangeRemote} />
        </div>
        <div className='col-6'>
          <label className='required'>Unit</label>
          <input type='text' value={itemOptions.unit} name='unit' id='unit' className={`form-control ${handleErrorField('unit').className}`} placeholder='Unit' onChange={handleInputOptionsChange}/>
          { errorMessage('unit') }
        </div>
      </div>
      <div className='form-group row'>
        <div className='col-5'>
          <label htmlFor='displayAs' className='required'>Display As</label>
          <select name='displayAs' value={itemOptions.displayAs} id='displayAs' className='form-control' onChange={handleInputOptionsChange}>
            <option key={1} value='input_box'>Input Box</option>
            <option key={2} value='input_box_with_control'>Input Box With Control</option>
            <option key={3} value='dropdown'>Dropdown</option>
          </select>
        </div>
      </div>
    </Fragment>
  )

  const multipleListOptions = (
    <Fragment>
      <hr/>
      <div className='form-group'>
        <label htmlFor='answerList' className='required'>Answer List</label>
        <textarea name='answerList' value={itemOptions.answerList} id='answerList' rows='7' className={`form-control ${handleErrorField('answerList').className}`} placeholder='Add one option per line' onChange={handleInputOptionsChange}/>
        { errorMessage('answerList') }
      </div>
      {item.type === 'list_with_single_answer' &&
        <div className='form-group row'>
          <div className='col-3'>
            <label htmlFor='displayAs' className='required'>Display As</label>
            <select name='displayAs' value={itemOptions.displayAs} id='displayAs' className='form-control' onChange={handleInputOptionsChange}>
              <option key={1} value='radio'>Radio</option>
              <option key={2} value='dropdown'>Dropdown</option>
            </select>
          </div>
        </div>
      }
    </Fragment>
  )

  const dateRangeOptions = (
    <Fragment>
      <hr/>
      <div className='form-group row'>
        <div className='col-6'>
          <label className='required'>Start Date Help Message</label>
          <input type='text' className={`form-control ${handleErrorField('startDateHelpMessage').className}`} value={itemOptions.startDateHelpMessage || ''} name='startDateHelpMessage' onChange={handleInputOptionsChange} id='range_options_start_date_help_message'/>
          { errorMessage('startDateHelpMessage') }
        </div>
        <div className='col-6'>
          <label className='required'>End Date Help Message</label>
          <input type='text' className={`form-control ${handleErrorField('endDateHelpMessage').className}`} value={itemOptions.endDateHelpMessage || ''} name='endDateHelpMessage' onChange={handleInputOptionsChange} id='range_options_end_date_help_message'/>
          { errorMessage('endDateHelpMessage') }
        </div>
      </div>
    </Fragment>
  )

  return (
    <li key={item.id} className='list-group-item tw-bg-gray-200'>
      <div className='font-italic font-weight-bold mb-4'>{item.id === 'new' ? 'New Field' : 'Edit Field'}</div>
      <div className='form-group row'>
        <label htmlFor='type' className='col-form-label col-4 required'>Type</label>
        <div className='col-4'>
          <select name='type' value={item.type} onChange={handleInputChange} className='custom-select text-capitalize'>
            {formTypes.map(item =>
              <option key={item} value={item}>{item.split('_').join(' ')}</option>
            )}
          </select>
        </div>
        <div className='col-4'>
          <div className='custom-control custom-checkbox col-form-label'>
            <input type='checkbox' name='isCompulsory' className='custom-control-input' id='isCompulsory' defaultChecked={Boolean(item.isCompulsory)} onChange={handleInputChange} />
            <label className='custom-control-label' htmlFor='isCompulsory'>Is compulsory</label>
          </div>
        </div>
      </div>
      <div className='form-group row'>
        <label htmlFor='label' className='col-form-label col-4 required'>Label</label>
        <div className='col-8'>
          <input type='text' name='label' value={item.label} autoFocus={props.editing} onChange={handleInputChange} className={`form-control ${handleErrorField('label').className}`}/>
          { errorMessage('label') }
        </div>
      </div>
      { item.type !== 'date_range' && (
        <div className='form-group row'>
          <label htmlFor='type' className='col-form-label col-4'>Help Message</label>
          <div className='col-8'>
            <input type='text' name='helpMessage' value={item.helpMessage} id='helpMessage' onChange={handleInputChange} className='form-control'/>
          </div>
        </div>
      )}
      { item.type === 'date_range' && dateRangeOptions }
      { item.type === 'number' && numberOptions }
      { ['list_with_single_answer', 'list_with_multiple_answer'].includes(item.type)  && multipleListOptions }
      <hr/>
      <div className='text-right'>
        {item.id === 'new' ? (
          <button type='button' className='btn btn-success rounded-pill px-3' onClick={() => props.addItem(item)}>
            <Plus className='feather'/>
            <span className='ml-1'>Add</span>
          </button>
          ) : (
          <button type='button' className='btn btn-success rounded-pill px-3' onClick={() => props.updateItem(item.id, item)}>
            <Check className='feather'/>
            <span className='ml-1'>Update</span>
          </button>
        )}
        <button type='button' className='btn btn-light ml-2 rounded-pill px-3' onClick={() => props.setEditing(false)} >
          <X className='feather'/>
          <span className='ml-1'>Cancel</span>
        </button>
      </div>
    </li>
  )
}

export default ItemForm