import { Controller } from "stimulus";
import { convertPrice } from "../../helpers/convert_price";

export default class extends Controller {
  static targets = [
    "shippingPrice",
    "shippingTableSpinner",
    "newShippingAddress",
    "dhlPrice",
    "dhlDuration",
    "dhlRadioButton",
  ];

  connect() {
    this.shippingAddressInput();
  }

  shippingAddressInput() {
    const newShippingAddress = document.getElementById("checkoutShippingAddress");

    if (!newShippingAddress.checked) {
      const el = document.querySelector('[name="checkout[shipping_address_id]"]');

      if (el.value) this.fetchByShippingAddressId(el.value);
    }
  }

  newShippingInput() {
    const city = document.querySelector('[name="checkout[city]"]').value;
    const countryCode = document.querySelector('[name="checkout[country_code]"]').value;
    const postalCode = document.querySelector('[name="checkout[zip_code]"]').value;

    if (city != "" && countryCode != "" && postalCode != "") {
      this.fetchByNewShippingAddress(city, countryCode, postalCode);
    }
  }

  shippingAddress() {
    const existingAddress = document.querySelector("[name='checkout[shipping_address_id]']");
    const newAddressFields = this.newShippingAddressTarget.querySelectorAll(
      "select, input:not([name*='checkout[address_optional]'])"
    );

    existingAddress.disabled = this.shippingCheckbox.checked;
    this.toggleNewShippingAddressField(newAddressFields, this.shippingCheckbox.checked);
  }

  selectShipping(event) {
    let price;
    const type = event.currentTarget.dataset.type;
    const totalProducts = parseFloat(document.getElementById("total-products").dataset.totalProducts);

    if (type === "dhl") {
      price = parseFloat(this.dhlPriceTarget.dataset.priceTag);
    } else {
      price = 0.0;
    }

    this.shippingPriceTarget.innerHTML = convertPrice(price);
    this.shippingPriceTarget.dataset.priceTag = price;

    const total = document.getElementById("total-checkout");
    total.innerHTML = convertPrice(totalProducts + price);
    total.dataset.priceTag = totalProducts + price;

    document.getElementById("cc-price").innerHTML = convertPrice(totalProducts + price);
    document.getElementById("cc-price").dataset.priceTag = totalProducts + price;
  }

  // Private

  toggleNewShippingAddressField(fields, checked = true) {
    fields.forEach((field) => {
      field.required = checked;
      field.disabled = !checked;

      if (checked == false) field.value = "";
    });
  }

  fetchByShippingAddressId(shippingId) {
    this.shippingPriceTarget.innerHTML = "-";
    this.shippingCheckbox.disabled = true;
    this.shippingTable.classList.add("d-none");
    this.shippingTable.insertAdjacentHTML("afterend", this.shippingSpinner());

    fetch(`/shippings/${shippingId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": this.token,
      },
    })
      .then((response) => {
        if (!response.ok) throw response;
        return response.json();
      })
      .then((data) => {
        this.dhlShippingInfo(data.dhl);
        this.shippingCheckbox.disabled = false;
        this.shippingTable.classList.remove("d-none");
        this.shippingTableSpinnerTarget.remove();
      })
      .catch((error) => {
        console.error(error);
        this.shippingCheckbox.disabled = false;
        this.shippingTable.classList.remove("d-none");
        this.shippingTableSpinnerTarget.remove();
      });
  }

  fetchByNewShippingAddress(city, countryCode, postalCode) {
    this.shippingPriceTarget.innerHTML = "-";
    this.shippingCheckbox.disabled = true;
    this.shippingCheckbox.disabled = true;
    this.shippingTable.classList.add("d-none");
    this.shippingTable.insertAdjacentHTML("afterend", this.shippingSpinner());

    fetch("/shippings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": this.token,
      },
      body: JSON.stringify({ city: city, country_code: countryCode, zip_code: postalCode }),
    })
      .then((response) => {
        if (!response.ok) throw response;
        return response.json();
      })
      .then((data) => {
        this.dhlShippingInfo(data.dhl);
        this.shippingCheckbox.disabled = false;
        this.shippingTable.classList.remove("d-none");
        this.shippingTableSpinnerTarget.remove();
      })
      .catch((error) => {
        console.error(error);
        this.shippingCheckbox.disabled = false;
        this.shippingTable.classList.remove("d-none");
        this.shippingTableSpinnerTarget.remove();
      });
  }

  dhlShippingInfo(data) {
    try {
      this.dhlDurationTarget.innerHTML = `Express Air Shipment`;
      this.dhlPriceTarget.innerHTML = convertPrice(data.amount || 0);
      this.dhlPriceTarget.dataset.priceTag = data.amount;
      this.dhlRadioButtonTarget.disabled = data.amount <= 0;
    } catch {
      return;
    }
  }

  shippingSpinner() {
    return `
      <div class="p-4 text-center" data-target="shippings--list.shippingTableSpinner">
        <div class="spinner-border text-primary" role="status" style="width: 3rem; height: 3rem;"></div>
        <div class="mt-2"><span class="text-muted">Loading shipping table</span></div>
      </div>
    `;
  }

  get token() {
    return document.querySelector('meta[name="csrf-token"]').content;
  }

  get shippingCheckbox() {
    return document.querySelector("input#checkoutShippingAddress");
  }

  get shippingTable() {
    return document.getElementById("shipping-table");
  }
}
