import React, { Fragment } from 'react'

const ListSingleAnswer = (props) => {
  const {
    type,
    options
  } = props.item
  const name = props.name || type

  const radio = (
    <Fragment>
      {options.answerList.split('\n').map((list, i) =>
        <div className='custom-control custom-radio' key={i}>
          <input type='radio' name={name} className='custom-control-input' value={list} id={`${name}_${list}`} defaultChecked={i===0} />
          <label className='custom-control-label' htmlFor={`${name}_${list}`}>{list}</label>
        </div>
      )}
    </Fragment>
  )

  const dropdown = (
    <select className='form-control' name={name} id={name}>
      {options.answerList.split('\n').map((list, i) =>
        <option key={i} value={list}>{list}</option>
      )}
    </select>
  )

  return(
    <Fragment>
      {options.displayAs === 'radio' && radio}
      {options.displayAs === 'dropdown' && dropdown}
    </Fragment>
  )
}

export default ListSingleAnswer