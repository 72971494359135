import { Controller } from "stimulus"
import { convertPrice } from "../../helpers/convert_price"

export default class extends Controller {
  static targets = [
    "price",
    "weight",
    "status",
    "quantity",
    "totalWeight",
    "totalSubPrice",
    "checkoutButton",
    "total",
  ]

  connect() {
    this.weightCalculation()
    this.cartCalculation()
    this.disabledCheckoutButton()
  }

  get token() {
    return document.querySelector('meta[name="csrf-token"]').content
  }

  updateProductStatus({ currentTarget }) {
    const { checked, dataset } = currentTarget
    const id = dataset.productId

    fetch(`/cart_products/${id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": this.token,
      },
      body: JSON.stringify({ cart_product: { checked: checked } }),
    })
      .then((response) => {
        if (!response.ok) throw response
        return response.json()
      })
      .then(() => {
        this.weightCalculation()
        this.cartCalculation()
        this.disabledCheckoutButton()
      })
  }

  updateProductQuantity({ currentTarget }) {
    const { value, dataset } = currentTarget
    const id = dataset.productId
    const currentEl = currentTarget.closest(`[data-product="cart-product-${id}"]`)
    const priceEl = currentEl.querySelector('[data-target="carts--cart-products.price"]')
    const weightEl = currentEl.querySelector('[data-target="carts--cart-products.weight"]')
    const quantityEl = currentEl.querySelector('[data-target="carts--cart-products.quantity"]')
    const minQuantityStatusEl = currentEl.querySelector('[data-min-quantity-status]')

    fetch(`/cart_products/${id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": this.token,
      },
      body: JSON.stringify({ cart_product: { quantity: value } }),
    })
      .then((response) => {
        if (!response.ok) throw response
        return response.json()
      })
      .then((data) => {
        quantityEl.dataset.cartQuantity = data.quantity
        priceEl.dataset.priceTag = data.price
        priceEl.innerHTML = convertPrice(data.price)
        weightEl.innerHTML = data.weight

        // min qty validation
        minQuantityStatusEl.dataset.minQuantityStatus = data.quantity >= quantityEl.min
      })
      .then(() => {
        this.weightCalculation()
        this.cartCalculation()
        this.disabledCheckoutButton()
      })
      .catch(() => (quantityEl.value = quantityEl.dataset.cartQuantity))
  }

  cartCalculation() {
    let totalPrice = 0.0
    const prices = this.priceTargets

    prices.forEach((price, index) => {
      if (this.statusTargets[index]?.checked) {
        price = parseFloat(price.dataset.priceTag)
        totalPrice += price
      }
    })

    this.totalSubPriceTarget.innerHTML = convertPrice(totalPrice)
    this.totalSubPriceTarget.dataset.priceTag = parseFloat(totalPrice)

    this.totalTarget.innerHTML = convertPrice(totalPrice)
    this.totalTarget.dataset.priceTag = parseFloat(totalPrice)
  }

  weightCalculation() {
    let totalWeight = 0.0
    const weights = this.weightTargets

    weights.forEach((weight, index) => {
      if (this.statusTargets[index]?.checked) {
        totalWeight += parseFloat(weight.innerHTML)
      }
    })

    this.totalWeightTarget.innerHTML = parseFloat(totalWeight).toFixed(2)
  }

  disabledCheckoutButton() {
    const checkedAll = this.statusTargets.filter(({checked}) => checked == true)
    const cantAllOrdered = checkedAll.filter(({dataset}) => String(dataset.ordered) == "false")
    const bellowMinQty = checkedAll.filter(({dataset}) => String(dataset.minQuantityStatus) == "false")

    if (checkedAll.length <= 0 || cantAllOrdered.length > 0 || bellowMinQty.length > 0) {
      this.checkoutButtonTarget.classList.remove("btn-dark")
      this.checkoutButtonTarget.classList.add("btn-outline-secondary")
      this.checkoutButtonTarget.setAttribute("disabled", true)
    } else {
      this.checkoutButtonTarget.classList.remove("btn-outline-secondary")
      this.checkoutButtonTarget.classList.add("btn-dark")
      this.checkoutButtonTarget.removeAttribute("disabled")
    }
  }
}
