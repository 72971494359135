import React, { Fragment, useState } from "react"

const InputDetail = ({
  name,
  prefix,
  scope,
  object,
  productUnits,
  sizings,
  categories,
  collaborators,
  editCreatedBy,
  permission,
}) => {
  const [sizeList, setSizeList] = useState(sizings || [])

  const productUnitOptions = productUnits.map((unit) => (
    <option key={unit.id} value={unit.id}>
      {unit.label}
    </option>
  ))

  const sizingOptions = sizeList.map((unit) => (
    <option key={unit.id} value={unit.id}>
      {unit.code}
    </option>
  ))

  const categoryList = categories.sort((a, b) => (a.label > b.label) ? 1 : -1).map((e, i) => (
    <option key={i} value={e.id}>
      {e.label}
    </option>
  ))

  const collaboratorList = collaborators.map((e, i) => (
    <option key={i} value={e.id}>
      {e.name}
    </option>
  ))

  const onCategoryChange = ({ currentTarget }) => {
    const { value } = currentTarget
    value ? fectchingSizing(value) : setSizeList([])
  }

  const fectchingSizing = async (categoryID) => {
    const response = await fetch(`/${scope}/sizings?category=${categoryID}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })

    const jsonData = await response.json()
    setSizeList([...jsonData])
  }

  return (
    <Fragment>
      {object.supplierName && permission && (
        <div className="form-group row align-items-center mt-6">
          <div className="col-12 col-md-2">
            <label>Supplier</label>
          </div>
          <div className="col-12 col-md-10">{object.supplierName}</div>
        </div>
      )}

      <div className="form-group row align-items-center">
        <div className="col-12 col-md-2">
          <label className="required" htmlFor={`${prefix}_code`}>
            Code
          </label>
        </div>
        <div className="col-12 col-md-10">
          <input
            type="text"
            name={`${name}[code]`}
            className="form-control"
            id={`${prefix}_code`}
            defaultValue={object.code}
          />
        </div>
      </div>

      <div className="form-group row align-items-center">
        <div className="col-12 col-md-2">
          <label className="required" htmlFor={`${prefix}_name`}>
            Name
          </label>
        </div>
        <div className="col-12 col-md-10">
          <input
            type="text"
            name={`${name}[name]`}
            className="form-control"
            id={`${prefix}_name`}
            defaultValue={object.name}
          />
        </div>
      </div>

      <div className="form-group row align-items-center">
        <div className="col-12 col-md-2">
          <label htmlFor={`${prefix}_short_name`}>Short Name</label>
        </div>
        <div className="col-12 col-md-10">
          <input
            type="text"
            name={`${name}[short_name]`}
            className="form-control"
            id={`${prefix}_short_name`}
            defaultValue={object.shortName}
          />
        </div>
      </div>

      <div className="form-group row align-items-center">
        <div className="col-12 col-md-2">
          <label>Categories</label>
        </div>
        <div className="col-12 col-md-10">
          <select
            name={`${name}[category_id]`}
            className="form-control select2-select"
            defaultValue={object.selectedCategory}
            onChange={onCategoryChange}
          >
            <option value="">- Select Category -</option>
            {categoryList}
          </select>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-12 col-md-2">
          <label htmlFor={`${prefix}_description`}>
            Description
            <div className="text-muted">
              Lengkapi dengan size 3 dimensi dan material yang dipakai dan tambahan info penting lainnya
            </div>
          </label>
        </div>
        <div className="col-12 col-md-10">
          <textarea
            name={`${name}[description]`}
            className="form-control"
            id={`${prefix}_description`}
            defaultValue={object.description}
          />
        </div>
      </div>

      <div className="form-group row align-items-center">
        <div className="col-12 col-md-2">
          <label className="required" htmlFor={`${prefix}_product_unit_id`}>
            Product Unit
          </label>
        </div>
        <div className="col-12 col-md-10">
          <select
            name={`${name}[product_unit_id]`}
            className="form-control"
            id={`${prefix}_product_unit_id`}
            defaultValue={object.productUnitId}
          >
            {productUnitOptions}
          </select>
        </div>
      </div>
{/*
      <div className="form-group row align-items-center">
        <div className="col-12 col-md-2">
          <label htmlFor={`${prefix}_sizing_id`}>Product Size</label>
        </div>
        <div className="col-12 col-md-10">
          <select
            name={`${name}[sizing_id]`}
            className="form-control"
            id={`${prefix}_sizing_id`}
            defaultValue={object.sizingId || ""}
          >
            <option value="">- Select Size -</option>
            {sizingOptions}
          </select>
        </div>
      </div> */}

      <div className="form-group row align-items-center">
        <div className="col-12 col-md-2">
          <label htmlFor={`${prefix}_weight`}>Weight (after packing)</label>
        </div>
        <div className="col-12 col-md-10">
          <div className="input-group">
            <input
              type="number"
              name={`${name}[weight]`}
              className="form-control"
              id={`${prefix}_weight`}
              defaultValue={object.weight}
              step="any"
            />
            <div className="input-group-append">
              <div className="input-group-text">gr</div>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group row align-items-center">
        <div className="col-12 col-md-2">
          <label htmlFor={`${prefix}_length`}>Length (after packing)</label>
        </div>
        <div className="col-12 col-md-10">
          <div className="input-group">
            <input
              type="number"
              name={`${name}[length]`}
              className="form-control"
              id={`${prefix}_length`}
              defaultValue={object.length}
              step="any"
            />
            <div className="input-group-append">
              <div className="input-group-text">cm</div>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group row align-items-center">
        <div className="col-12 col-md-2">
          <label htmlFor={`${prefix}_width`}>Width (after packing)</label>
        </div>
        <div className="col-12 col-md-10">
          <div className="input-group">
            <input
              type="number"
              name={`${name}[width]`}
              className="form-control"
              id={`${prefix}_width`}
              defaultValue={object.width}
              step="any"
            />
            <div className="input-group-append">
              <div className="input-group-text">cm</div>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group row align-items-center">
        <div className="col-12 col-md-2">
          <label htmlFor={`${prefix}_height`}>Height (after packing)</label>
        </div>
        <div className="col-12 col-md-10">
          <div className="input-group">
            <input
              type="number"
              name={`${name}[height]`}
              className="form-control"
              id={`${prefix}_height`}
              defaultValue={object.height}
              step="any"
            />
            <div className="input-group-append">
              <div className="input-group-text">cm</div>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-12 col-md-2">
          <label htmlFor={`${prefix}_shipping_types`}>Shipping Types</label>
        </div>
        <div className="col-12 col-md-10">
          <div>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                value="1"
                name={`${prefix}[shipping_type][]`}
                className="custom-control-input"
                id="product_shipping_types_dhl"
                defaultChecked={object.shippingType.includes(1)}
              />
              <label
                className="custom-control-label"
                htmlFor="product_shipping_types_dhl"
              >
                Air Shipment (Hilangkan centang apabila produk tidak bisa dikirim melalui udara)
              </label>
            </div>

            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                value="2"
                name={`${prefix}[shipping_type][]`}
                className="custom-control-input"
                id="product_shipping_types_custom_shipping"
                defaultChecked={object.shippingType.includes(2)}
              />
              <label
                className="custom-control-label"
                htmlFor="product_shipping_types_custom_shipping"
              >
                Custom Shipping
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-12 col-md-2">
          <label htmlFor={`${prefix}_terms_and_conditions`}>
            Terms and Conditions
          </label>
        </div>
        <div className="col-12 col-md-10">
          <textarea
            name={`${name}[terms_and_conditions]`}
            className="form-control"
            id={`${prefix}_terms_and_conditions`}
            defaultValue={object.termsAndConditions}
          />
        </div>
      </div>

      {permission && (
        <div className="form-group row">
          <div className="col-12 col-md-2">
            <label htmlFor={`${prefix}_is_published`}>Published</label>
          </div>
          <div className="col-12 col-md-10">
            <input type="hidden" name={`${name}[is_published]`} value={false} />
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                name={`${name}[is_published]`}
                value={true}
                defaultChecked={object.isPublished}
                className="custom-control-input"
                id={`${name}_is_published`}
              />
              <label
                htmlFor={`${name}_is_published`}
                className="custom-control-label"
              >
                Yes
              </label>
            </div>
          </div>
        </div>
      )}

      {permission && (
        <div className="form-group row">
          <div className="col-12 col-md-2">
            <label htmlFor={`${prefix}_is_featured`}>Featured</label>
          </div>
          <div className="col-12 col-md-10">
            <input type="hidden" name={`${name}[is_featured]`} value={false} />
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                name={`${name}[is_featured]`}
                value={true}
                defaultChecked={object.isFeatured}
                className="custom-control-input"
                id={`${name}_is_featured`}
              />
              <label
                htmlFor={`${name}_is_featured`}
                className="custom-control-label"
              >
                Yes
              </label>
            </div>
          </div>
        </div>
      )}

      {/* {editCreatedBy && (
        <div className="form-group row align-items-center">
          <div className="col-12 col-md-2">
            <label>Created By</label>
          </div>
          <div className="col-12 col-md-6">
            <select
              name={`${name}[created_by_id]`}
              className="form-control select2-select"
              defaultValue={object.createdById}
            >
              <option value="">- Select Merchant/Collaborator -</option>
              {collaboratorList}
            </select>
          </div>
        </div>
      )} */}
    </Fragment>
  )
}

export default InputDetail
