import React, { Fragment, useState, useRef, useEffect } from "react";
import { DirectUpload } from "@rails/activestorage";
import Cropper from "cropperjs";
import $ from "jquery";

import Uploader from "../../helpers/uploader";

const ImageUploader = (props) => {
  if (props.avatar) require("./avatar_cropper.scss");

  let imageRef = useRef(null);
  let modalRef = useRef(null);
  let progressRef = useRef(null);

  const [errorAlert, setErrorAlert] = useState(false);
  const [destroyedImage, setDestroyImage] = useState(false);
  const [cropper, setCropper] = useState(null);
  const [signedId, setSignedId] = useState(null);
  const [fileInput, setFileInput] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [hasImage, setHasImage] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleImageInput = (event) => {
    let reader, file;
    let files = event.currentTarget.files;

    if (files && files.length > 0) {
      file = files[0];

      setFileInput(file);
      reader = new FileReader();
      reader.onload = () => {
        imageRef.current.src = reader.result;
        setShowModal(true);
        $(modalRef.current).modal({ show: true, backdrop: "static" });
      };
      reader.readAsDataURL(file);
    }

    event.currentTarget.value = null;
  };

  const handleCropImage = () => {
    const defaultImgRef = cropper
      .getCroppedCanvas({ width: props.width, height: props.height })
      .toDataURL(fileInput.type);

    cropper.getCroppedCanvas().toBlob((blobCanvas) => {
      blobCanvas.name = fileInput.name;

      const source = { url: props.directUpload, ref: progressRef.current };
      const upload = new Uploader(blobCanvas, source);
      upload.start(
        (_) => {
          setSignedId(null);
          setErrorAlert(true);
        },
        (blob) => {
          setSignedId(blob.signed_id);
          setImageUrl(`url(${defaultImgRef})`);
          setHasImage(true);
          setShowModal(false);
          cropper.destroy();
          $(modalRef.current).modal("hide");
        }
      );
    });
  };

  const handleOnCancelled = () => {
    setSignedId(null);
    setShowModal(false);

    cropper.destroy();
    imageRef.current.src = "";
    $(modalRef.current).modal("hide");
  };

  const removePicture = () => setDestroyImage(true);

  useEffect(() => {
    if (props.imageUrl) {
      setImageUrl(`url(${props.imageUrl})`);
      setHasImage(true);
    } else {
      setImageUrl("#eeeeee");
    }
  }, []);

  useEffect(() => {
    let timer;
    if (errorAlert === true) timer = setTimeout(() => setErrorAlert(false), 3500);

    return () => { clearTimeout(timer) };
  }, [errorAlert]);

  useEffect(() => {
    if (destroyedImage) {
      setCropper(null);
      setSignedId(null);
      setFileInput(null);
      setImageUrl("#eeeeee");
      setHasImage(false);
    }
  }, [destroyedImage]);

  useEffect(() => {
    if (showModal) {
      $(modalRef.current).one("shown.bs.modal", function () {
        let initCropper = new Cropper(imageRef.current, {
          aspectRatio: 1,
          viewMode: 3,
          dragMode: "move",
          guides: false,
          center: false,
          responsive: true,
          highlight: false,
          cropBoxMovable: true,
          cropBoxResizable: true,
          toggleDragModeOnDblclick: false,
        });

        setCropper(initCropper);
      });
    } else {
      setCropper(null);
      setFileInput(null);
    }
  }, [showModal]);

  return (
    <Fragment>
      <div className="input-image-box" style={{ background: imageUrl }}>
        {!hasImage && (
          <>
            <div className="text-center">
              <h1 className="text-muted">+</h1>
              <span className="text-muted">Add image</span>
            </div>

            {destroyedImage && <input type="hidden" name={`${props.attrName}[_destroy]`} value={true} />}
          </>
        )}

        {hasImage && (
          <>
            <button
              type="button"
              className="btn btn-xs btn-circle btn-danger card-action card-action-right"
              data-toggle="button"
              onClick={removePicture}>
              <i className="fe fe-trash"></i>
            </button>
          </>
        )}

        <input
          className="input-image-file"
          type="file"
          accept="image/png, image/jpg, image/jpeg"
          onChange={handleImageInput}
        />
      </div>

      {signedId && <input type="hidden" id={props.attrId} name={`${props.attrName}[signed_id]`} value={signedId} />}

      <Fragment>
        <div ref={modalRef} className="modal fade" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 id="modalLabel" className="modal-title">
                  Crop the Image
                </h5>

                <button className="close" type="button" aria-label="Close" onClick={handleOnCancelled}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body p-0">
                {errorAlert && (
                  <div className="m-0 alert alert-warning">
                    <span>Something wrong when upload images</span>
                  </div>
                )}

                <div className="img-container">
                  <img id="cropper-img" src="" alt="image-src" ref={imageRef} />
                </div>

                <div ref={progressRef} className="d-none progress" style={{ height: "0.80rem" }}>
                  <div
                    id="direct-upload-progress"
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    style={{ width: "0%" }}
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100">
                    0%
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button className="btn btn-secondary" type="button" onClick={handleOnCancelled}>
                  Cancel
                </button>
                <button className="btn btn-primary" type="button" onClick={handleCropImage}>
                  Crop
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </Fragment>
  );
};

export default ImageUploader;
