import React, { useState, Fragment } from 'react'
import ItemPreview from './FormBuilder/ItemPreview'
import ItemForm from './FormBuilder/ItemForm'
import { Plus } from 'react-feather'

function FormViewer(props) {
  const itemsData = []
  const name = props.inputName

  const initialItem = {
    id: 'new',
    label: '',
    type: 'text',
    options: '',
    isRequired: false
  }

  const [items, setItems] = useState(itemsData)
  const [currentItem, setCurrentItem] = useState(initialItem)
  const [editing, setEditing] = useState(false)

  // const addItem = (item) => {
  //   setEditing(false)

  //   item.id = items.length + 1
  //   setItems([...items, item])
  // }

  // const removeItem = (id) => {
  //   setEditing(false)

  //   setItems(items.filter(item => item.id !== id))
  // }

  // const updateItem = (id, updatedItem) => {
  //   setEditing(false)

  //   setItems(items.map(item => (item.id === id ? updatedItem : item)))
  // }

  // const editItem = (item) => {
  //   event.preventDefault()
  //   setEditing(true)

  //   setCurrentItem({
  //     id: item.id,
  //     label: item.label,
  //     type: item.type,
  //     options: item.options,
  //   })
  // }

  // const openNewForm = () => {
  //   event.preventDefault()
  //   setEditing(true)
  //   setCurrentItem(initialItem)
  // }

  return (
    <Fragment>
      {items.length > 0 || editing ? (
        <ul className='list-group'>
          {items.map(item =>
            <Fragment key={item.id}>
              {editing && item.id === currentItem.id ? (
                <ItemForm key={item.id}
                  item={item}
                  editing={editing}
                  setEditing={setEditing}
                  addItem={addItem}
                  updateItem={updateItem}
                  removeItem={removeItem} />
              ) : (
                <ItemPreview key={item.id}
                  item={item}
                  editItem={editItem}
                  name={name} />
              )}
            </Fragment>
          )}
          {editing && currentItem.id === initialItem.id &&
            <ItemForm key={currentItem.id}
              item={currentItem}
              editing={editing}
              setEditing={setEditing}
              addItem={addItem} />
          }
        </ul>
      ) : (
        <div className='text-muted mb-1'>Nothing is here yet.</div>
      )}
      {!editing &&
        <a href='#' className='btn btn-light mt-2' onClick={openNewForm}>
          <Plus className='feather'/>
          <span className='ml-1'>Add</span>
        </a>
      }
    </Fragment>
  )
}

export default FormViewer
