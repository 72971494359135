import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["addressDetail"];

  connect() {
    const checkoutElement = document.getElementById("shipping-address-form");
    this.shippingList = this.application.getControllerForElementAndIdentifier(
      checkoutElement,
      "shippings--form"
    );
  }

  changeShippingAddress(event) {
    event.preventDefault();

    const element = document.getElementById("currentAddressDetail");
    const input = document.querySelector("[name='checkout[shipping_address_id]']");
    const selectedAddress = this.addressDetailTarget;

    element.innerHTML = selectedAddress.innerHTML;
    input.value = selectedAddress.dataset.addressId;
    $("#shippingAddressesModal").modal("hide");

    this.shippingList.changeShippingAddress();
  }
}
