import { Controller } from "stimulus"
import { numberToCurrency } from "../../../helpers/currency"

export default class extends Controller {
  static targets = ["selectField", "firstName", "lastName", "email"]

  onError(event) {
    const [data] = event.detail
    const model = data.model

    Object.keys(data.errors).forEach((key) => {
      const field = document.getElementById(`${model}_${key}`)

      if (field != null) {
        const message = data.errors[key]
        field.classList.add("is-invalid")
        field.insertAdjacentHTML(
          "afterend",
          `</span id="error-message text-danger">${message}</small>`
        )
      }
    })
  }

  customerSelect(event) {
    const value = event.currentTarget.value

    fetch(`/merchant/customers/${value}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) throw response
        return response.json()
      })
      .then((data) => {
        this.firstNameTarget.value = data.customer.first_name
        this.lastNameTarget.value = data.customer.last_name
        this.emailTarget.value = data.customer.email

        this.firstNameTarget.disabled = false
        this.lastNameTarget.disabled = false
        this.emailTarget.disabled = false

        this.checkoutOptions(data.checkouts)
      })
      .catch((error) => console.error(error))
  }

  checkoutOptions(options) {
    const selectBox = this.selectFieldTarget
    $(selectBox).children("option:not(:first)").remove()

    options.forEach((option) => {
      if (["custom_shipping", "pick_up"].includes(option?.shipping_details?.shipping_type)) {
        const opt = document.createElement("option")
        opt.value = option.id
        opt.text = `${option.code} - ${option.first_name} ${option.last_name} - ${numberToCurrency(
          'IDR', option.total_amount
        )}`
        selectBox.appendChild(opt)
      }
    })
  }
}
