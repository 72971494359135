import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  onError(event) {
    console.log(event)
  }

  onSuccess(event) {
    const [data] = event.detail
    document.getElementById("payment_confirmation").innerHTML = data.content
    $("#payment_confirmation_modal").modal("show")
  }
}
