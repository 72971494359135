import React, { Fragment, useEffect, useState } from "react"
import { X } from "react-feather"
import PriceCalculator from "./price_calculator"

const InputPriceRange = (props) => {
  const priceRangeInitialState = {
    id: 0,
    min_qty: 1,
    max_qty: 1,
    price: 0,
    selling_price: 0,
    processing_time: 0,
    loading: false,
  }

  const { name, prefix, object, bgColor, permission } = props
  const [priceRanges, setPriceRanges] = useState([])
  const [isPreorder, setIsPreorder] = useState(object.preorder)
  const [timer, setTimer] = useState(0)
  const prefixInputName = `${name}[price_ranges][]`
  const prefixInputId = `${name}_price_ranges`
  const canEditSellingPrice = permission
  const priceRangeColWidth = isPreorder ? [(canEditSellingPrice ? 2 : 3), 3] : [(canEditSellingPrice ? 2 : 4), 4]
  const parentId = object.id || 0

  const handlePreorderChange = (e) => {
    setIsPreorder(!isPreorder)
  }

  const handleAdd = () => {
    const id =
      priceRanges.length > 0 ? priceRanges[priceRanges.length - 1].id + 1 : 0
    const newPriceRange = { ...priceRangeInitialState, id: id }

    setPriceRanges([...priceRanges, { ...newPriceRange }])
  }

  const handleDelete = (id) => {
    const newPriceRanges = priceRanges.filter((d) => d.id != id)
    setPriceRanges(newPriceRanges)
  }

  const calculateSellingPrice = (index, value) => {
    const price = [...priceRanges]
    price[index].loading = true

    setPriceRanges(price)
    clearTimeout(timer)

    const newTimer = setTimeout(() => {
      PriceCalculator(parseInt(value)).then((result) => {
        const currentPriceRange = [...priceRanges]
        currentPriceRange[index].selling_price = result
        currentPriceRange[index].loading = false
        setPriceRanges(currentPriceRange)
      })
    }, 3500)

    setTimer(newTimer)
  }

  useEffect(() => {
    const priceRanges = object.priceRanges.map((pr, i) => {
      return { ...pr, id: i }
    })
    setPriceRanges(priceRanges)
  }, [])

  useEffect(() => {
    object.priceRanges.map((item, index) => {
      if (item.price !== 0) {
        PriceCalculator(parseInt(item.price)).then(result => {
          const currentPriceRange = [...priceRanges]
          currentPriceRange[index].selling_price = result
          setPriceRanges(currentPriceRange)
        })
      }
    })
  }, [])

  const priceRangeList = priceRanges.map((item, index) => (
    <Fragment key={item.id}>
      <div className={`card bg-${bgColor || "light"} mt-2`}>
        <div className="card-body py-0">
          <div className="row align-items-start no-gutters mt-4">
            <div className={`col-12 col-md-${priceRangeColWidth[0]} pr-2`}>
              <label htmlFor={`${prefixInputId}_${item.id}_min_qty`}>
                Min. Quantity
              </label>
              <input
                type="number"
                name={`${prefixInputName}[min_qty]`}
                id={`${prefixInputId}_${item.id}_min_qty`}
                defaultValue={item.min_qty}
                className="form-control"
                min={1}
                step={1}
              />
            </div>
            <div className={`col-12 col-md-${priceRangeColWidth[0]} pr-2`}>
              <label htmlFor={`${prefixInputId}_${item.id}_max_qty`}>
                Max. Quantity
              </label>
              <input
                type="number"
                name={`${prefixInputName}[max_qty]`}
                id={`${prefixInputId}_${item.id}_max_qty`}
                defaultValue={item.max_qty}
                className="form-control"
                min={1}
                step={1}
              />
            </div>
            <div className={`col-12 col-md-${priceRangeColWidth[1]} pr-2`}>
              <label htmlFor={`${prefixInputId}_${item.id}_price`}>
                Nett Price
              </label>
              <input
                type="number"
                name={`${prefixInputName}[price]`}
                id={`${prefixInputId}_${item.id}_price`}
                defaultValue={item.price}
                className="form-control"
                step="any"
                onChange={(e) => calculateSellingPrice(index, e.currentTarget.value)}
              />
            </div>
            {props.permission &&
              <div className={`col-12 col-md-${priceRangeColWidth[1]} pr-2`}>
                <label htmlFor={`${prefixInputId}_${item.id}_selling_price`}>
                  Selling Price
                </label>
                {item.loading && (
                  <div className="text-muted">Calculating selling price...</div>
                )}
                {!item.loading && (
                  <Fragment>
                    <div className="input-group mb-2">
                      <input
                        type="number"
                        name={`${prefixInputName}[selling_price]`}
                        id={`${prefixInputId}_${item.id}_selling_price`}
                        defaultValue={item.selling_price}
                        className="form-control"
                        step="any"
                      />
                    </div>

                    {item.selling_price && (
                      <span className="ml-1 text-muted">
                        Suggestion: {item.selling_price}
                      </span>
                    )}
                    {!item.selling_price && (
                      <span className="ml-1 text-muted">
                        Price suggestion is not available for this range. Add rule for this range here
                        <a href="/merchant/price_calculators" className="ml-1" target="_blank">[link]</a>
                      </span>
                    )}
                  </Fragment>
                )}
              </div>
            }
            {isPreorder && (
              <div className={`col-12 col-md-${canEditSellingPrice ? 2 : priceRangeColWidth[1]} pr-2`}>
                <label htmlFor={`${prefixInputId}_${item.id}_processing_time`}>
                  Processing Time
                </label>
                <input
                  type="number"
                  name={`${prefixInputName}[processing_time]`}
                  id={`${prefixInputId}_${item.id}_processing_time`}
                  defaultValue={item.processing_time}
                  className="form-control"
                  min={1}
                  step={1}
                />
              </div>
            )}
          </div>
          <div className="text-right">
            <button
              type="button"
              className="btn btn-link btn-sm"
              onClick={() => {
                handleDelete(item.id)
              }}
            >
              <X />
              <span className="ml-2">Delete</span>
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  ))

  return (
    <Fragment>
      <div className="form-group row align-items-center">
        <div className="col-12 col-md-2">
          <label htmlFor={`product_${parentId}_preorder`}>Preorder</label>
        </div>
        <div className="col-12 col-md-10">
          <div className="custom-control custom-checkbox">
            <input
              type="hidden"
              name={`${name}[preorder]`}
              value={false}
              readOnly={true}
            />
            <input
              type="checkbox"
              name={`${name}[preorder]`}
              id={`product_${parentId}_preorder`}
              className="custom-control-input"
              value={isPreorder}
              checked={isPreorder}
              onChange={handlePreorderChange}
            />
            <label
              htmlFor={`product_${parentId}_preorder`}
              className="custom-control-label"
            >
              Yes
            </label>
          </div>
        </div>
      </div>

      {isPreorder && priceRanges.length === 0 && (
        <div className="form-group row align-items-center">
          <div className="col-12 col-md-2">
            <label htmlFor={`product_${parentId}_default_processing_time`}>
              Processing Time
            </label>
          </div>
          <div className="col-12 col-md-10">
            <input
              type="number"
              name={`${name}[default_processing_time]`}
              id={`product_${parentId}_default_processing_time`}
              className="form-control"
              defaultValue={object.defaultProcessingTime}
            />
          </div>
        </div>
      )}

      <div className="form-group row">
        <div className="col-12 col-md-2">
          <label htmlFor={`${prefix}_price_range`}>Price Range</label>
        </div>
        <div className="col-12 col-md-10">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm mb-4"
            onClick={handleAdd}
          >
            Add Price Range
          </button>
          {priceRangeList}
        </div>
      </div>
    </Fragment>
  )
}

export default InputPriceRange

