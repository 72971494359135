import React, { useState, Fragment } from 'react'
import ItemPreview from './FormBuilder/ItemPreview'
import ItemForm from './FormBuilder/ItemForm'
import { Plus } from 'react-feather'

function FormBuilder(props) {
  const itemsData = Object.values(props.detailStructure)
  const name = props.inputName

  const initialItem = {
    id: 'new',
    label: '',
    type: 'text',
    helpMessage: '',
    options: {},
    isCompulsory: false
  }

  const [items, setItems] = useState(itemsData)
  const [currentItem, setCurrentItem] = useState(initialItem)
  const [editing, setEditing] = useState(false)
  const [errors, setErrors] = useState([])
  const validateFields = ['label']
  const validateOptionFields = ['unit', 'answerList', 'startDateHelpMessage', 'endDateHelpMessage']

  const addItem = (item) => {
    let currenErrors
    let fieldErrors = {}

    // Buat object errors yang akan dikirim ke state errors
    validateFields.filter(field => {
      if (eval(`item.${field}`) === '') {
        fieldErrors[field] = "Can't be Blank"
      }
    })

    validateOptionFields.filter(field => {
      if (eval(`item.options.${field}`) === '') {
        fieldErrors[field] = "Can't be Blank"
      }
    })

    if (Object.keys(fieldErrors).length === 0) {
      setEditing(false)
      const id = items.length === 0 ? items.length : parseInt(items[items.length - 1].id)
      item.id = id + 1
      setItems([...items, item])
      currenErrors = []
    } else {
      setEditing(true)
      currenErrors = fieldErrors
    }

    setErrors(currenErrors)
  }

  const removeItem = (id) => {
    setEditing(false)

    setItems(items.filter(item => item.id !== id))
  }

  const updateItem = (id, updatedItem) => {
    let currenErrors
    let fieldErrors = {}

    // Buat object errors yang akan dikirim ke state errors
    validateFields.filter(field => {
      if (eval(`updatedItem.${field}`) === '') {
        fieldErrors[field] = "Can't be Blank"
      }
    })

    validateOptionFields.filter(field => {
      if (eval(`updatedItem.options.${field}`) === '') {
        fieldErrors[field] = "Can't be Blank"
      }
    })

    if (Object.keys(fieldErrors).length === 0) {
      setEditing(false)
      setItems(items.map(item => (item.id === id ? updatedItem : item)))
      currenErrors = []
    } else {
      setEditing(true)
      currenErrors = fieldErrors
    }

    setErrors(currenErrors)
  }

  const editItem = (item) => {
    event.preventDefault()
    setEditing(true)

    setCurrentItem({
      id: item.id,
      label: item.label,
      type: item.type,
      helpMessage: item.helpMessage,
      options: item.options
    })
  }

  const openNewForm = () => {
    event.preventDefault()
    setEditing(true)
    setCurrentItem(initialItem)
  }

  return (
    <Fragment>
      {items.length > 0 || editing ? (
        <ul className='list-group'>
          {items.map(item =>
            <Fragment key={item.id}>
              {editing && item.id === currentItem.id ? (
                <ItemForm key={item.id}
                  item={item}
                  editing={editing}
                  setEditing={setEditing}
                  addItem={addItem}
                  updateItem={updateItem}
                  removeItem={removeItem}
                  errors={errors} />
              ) : (
                  <ItemPreview key={item.id}
                    item={item}
                    editItem={editItem}
                    name={name} />
                )}
            </Fragment>
          )}
          {editing && currentItem.id === initialItem.id &&
            <ItemForm key={currentItem.id}
              item={currentItem}
              editing={editing}
              setEditing={setEditing}
              addItem={addItem}
              errors={errors} />
          }
        </ul>
      ) : (
          <div className='card'>
            <div className='card-body p-3'>
              <div className='text-muted mb-1'>Nothing is here yet.</div>
            </div>
          </div>
        )}
      {!editing &&
        <div className='text-right'>
          <a href='#' id={props.id} className='btn btn-light mt-2 rounded-pill px-3' onClick={openNewForm}>
            <Plus className='feather' />
            <span className='ml-1'>Add more</span>
          </a>
        </div>
      }
    </Fragment>
  )
}

export default FormBuilder
