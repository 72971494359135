import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["shippingDate", "template"]

  editShippingDate(event) {
    event.preventDefault()
    event.currentTarget.remove()

    const template = this.templateTarget.content.cloneNode(true)
    this.shippingDateTarget.innerHTML = ""
    this.shippingDateTarget.append(template)
  }
}
