import React, { Fragment } from "react"
import InputDetail from "./InputDetail"
import InputPricing from "./InputPricing"
import ImageUploader from "../FormBuilder/ImageUploader"
import InputPriceRange from "./InputPriceRange"

const Form = (props) => {
  const scope = props.scope
  const editCreatedBy = props.data.editCreatedBy
  const productUnits = props.data.productUnits
  const sizings = props.data.sizings
  const categories = props.data.categories
  const product = props.data.product
  const collaborators = props.data.collaborators
  const pricingPrefixInputName = "product[pricings_attributes]"

  const randomID = (index) => Math.floor(Math.random() * 100000) + index

  return (
    <Fragment>
      <InputDetail
        name="product"
        prefix="product"
        scope={scope}
        object={product}
        productUnits={productUnits}
        sizings={sizings}
        categories={categories}
        collaborators={collaborators}
        editCreatedBy={editCreatedBy}
        permission={props.data.permission}
      />

      <div className="form-group row">
        <div className="col-12 col-md-2">
          <label htmlFor="package-images">Images</label>
        </div>

        <div className="col-12 col-md-10 row">
          {product.images && (
            <Fragment>
              {product.images.map((image, index) => (
                <div key={index} className="col-lg-3 col-md-3 col-sm-6">
                  <input
                    type="hidden"
                    name={`product[product_images][${image.id}][id]`}
                    value={image.id}
                  />

                  <ImageUploader
                    height={170}
                    width={250}
                    imageId={image.id}
                    attrName={`product[product_images][${image.id}]`}
                    attrId="product_images"
                    imageUrl={image.imageUrl}
                    directUpload="/rails/active_storage/direct_uploads"
                  />
                </div>
              ))}
            </Fragment>
          )}

          {product.imageLength < 8 && (
            <Fragment>
              {[...Array(8 - product.imageLength)].map((arr, index) => (
                <div key={index} className="col-lg-3 col-md-3 col-sm-6">
                  <ImageUploader
                    height={170}
                    width={250}
                    attrName={`product[product_images][${randomID(index)}]`}
                    attrId="product_images"
                    directUpload="/rails/active_storage/direct_uploads"
                  />
                </div>
              ))}
            </Fragment>
          )}
        </div>
      </div>

      <div className="form-group row align-items-center">
        <div className="col-12 col-md-2">
          <label>Video URL</label>
        </div>

        <div className="col-12 col-md-6">
          <input type="text" name="product[videos][][url]" className="form-control" defaultValue={product.videos[0]?.url}/>
        </div>
        <div className="col">
          <span className="text-muted">*Support for <a href="https://youtube.com" target="_blank">Youtube</a> video only</span>
        </div>
      </div>

      <h4 className="mt-5 mb-3 text-muted">Pricing</h4>
      <div>
        {product.pricing.isStored && (
          <input
            type="hidden"
            name={`${pricingPrefixInputName}[0][id]`}
            defaultValue={product.pricing.id}
          />
        )}

        <InputPricing
          name={`${pricingPrefixInputName}[0]`}
          prefix="product_pricing_attributes"
          object={product.pricing}
          permission={props.data.permission}
        />

        <InputPriceRange name="product" prefix="product" object={product} permission={props.data.permission} />
      </div>
    </Fragment>
  )
}

export default Form
