import React, { Fragment, useState } from 'react'
import { Plus, Minus } from 'react-feather'
import { rangeArray } from '../Utils'

const Number = (props) => {
  const {
    isCompulsory,
    type,
    helpMessage,
    options
  } = props.item

  const [error, setError] = useState(props.error || {})
  const name = props.name || type
  const buttonClassName = props.buttonClassName || 'btn-secondary'

  const hasMaxNumber = Object.keys(options).includes('max')
  const hasMinNumber = Object.keys(options).includes('min')
  const minValue = hasMinNumber ? parseInt(options.min) : parseInt(props.value)
  const maxValue = parseInt(options.max)
  const interval = parseInt(options.interval)
  const unitLabel = options.unit
  const displayAs = options.displayAs

  const [value, setValue] = useState(minValue)
  const hasError = Object.keys(error).length > 0
  const errorClassName = hasError && 'is-invalid'
  const rangeNumbers = rangeArray(minValue, maxValue/interval)

  const handleClickButton = (type) => {
    let currentError = {}
    let number = value
    if (type === 'plus') {
      if (hasMaxNumber) {
        if (number < maxValue) {
          number+=interval
        } else {
          currentError = { message: `Maximum number is ${maxValue}` }
        }
      } else {
        number+=interval
      }
    } else {
      if (hasMinNumber) {
        if (number > minValue) {
          number-=interval
        } else {
          currentError = { message: `Minimum number is ${minValue}` }
        }
      } else {
        number-=interval
      }
    }

    if (!hasMinNumber && !hasMaxNumber) {
      props.handleInputOptionsChange({name: name, value: number})
    }

    setValue(number)
    setError(currentError)
  }

  const handleInputChange = (event) => {
    let currentError = {}
    const currentValue = parseInt(event.target.value)

    setValue(currentValue)

    if (isNaN(currentValue) && isCompulsory) {
      currentError = { message: "Number can't be blank" }
    } else if ((currentValue > maxValue) || (currentValue < minValue) && hasMinNumber && hasMaxNumber) {
      currentError = { message: `Number is out of range (${minValue}-${maxValue})` }
    }

    if (!hasMinNumber && !hasMaxNumber) {
      props.handleInputOptionsChange({name: name, value: currentValue})
    }

    setError(currentError)
  }

  const inputBox = (
    <div className='input-group'>
      <div className='input-group-prepend'>
        <span className='input-group-text'>{unitLabel}</span>
      </div>
      <input type='number' placeholder={helpMessage} className={`form-control ${errorClassName}`} name={name} value={value} onChange={handleInputChange}/>
    </div>
  )

  const inputBoxWithControl = (
    <div className='input-group'>
      <div className='input-group-prepend'>
        <button type='button' className={`btn ${buttonClassName}`} onClick={() => handleClickButton('minus')}>
          <Minus className='feather'/>
        </button>
      </div>
      <input type='number' placeholder={helpMessage} className={`form-control text-center ${errorClassName}`} name={name} value={value} onChange={handleInputChange}/>
      <div className='input-group-append'>
        <button type='button' className={`btn ${buttonClassName}`} onClick={() => handleClickButton('plus')}>
          <Plus className='feather'/>
        </button>
      </div>
    </div>
  )

  const dropdown = (
    <div className='input-group'>
      <div className='input-group-prepend'>
        <span className='input-group-text'>{unitLabel}</span>
      </div>
      <select className={`form-control ${errorClassName}`} name={name}>
        {
          rangeNumbers.map((number, i) => {
            const result = number*interval

            return (
              <Fragment key={i}>
                {(i === 0) && (minValue !== result) && <option value={minValue}>{minValue}</option>}
                <option value={result}>{result}</option>
                {(i === rangeNumbers.length-1) && (maxValue !== result) && <option value={maxValue}>{maxValue}</option>}
              </Fragment>
            )
          })
        }
      </select>
    </div>
  )

  return(
    <Fragment>
      { displayAs === 'input_box' && inputBox }
      { displayAs === 'input_box_with_control' && inputBoxWithControl }
      { displayAs === 'dropdown' && dropdown }
      { hasError && <div className='text-danger mt-2'>{error.message}</div> }
    </Fragment>
  )
}

export default Number