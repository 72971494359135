// https://github.com/kenwheeler/slick
import 'slick-carousel/slick/slick'


document.addEventListener('turbolinks:load', () => {
  $('.product-carousel').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.product-carousel-nav',
    adaptiveHeight: false
  });

  $('.product-carousel-nav').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.product-carousel',
    dots: false,
    focusOnSelect: true,
    arrows: false,
    swipe: false,
    variableWidth: true,
    adaptiveHeight: false
  });

  $('.banner-carousel').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true
  });
})
