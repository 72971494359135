import { Controller } from 'stimulus'
import { numberToCurrency } from '../helpers/currency'
import { exchangePrice, exchangeCurrency } from '../helpers/convert_price'

export default class extends Controller {
  onProductQuantityChange(event) {
    try {
      const ranges = JSON.parse(event.currentTarget.dataset.ranges).sort((a,b) => parseInt(a.max_qty)-parseInt(b.max_qty))
      const value = parseInt(event.currentTarget.value)
      let price = event.currentTarget.dataset.defaultPrice
      const maxQuantity = parseInt(ranges[ranges.length-1].max_qty)

      if (value > maxQuantity) {
        const result = ranges[ranges.length-1]
        price = result.selling_price
        processingTime = parseInt(result.processing_time)
      } else {
        const results = ranges.filter(r => (value >= parseInt(r.min_qty)) && (value <= parseInt(r.max_qty)))

        if (results.length > 0) {
          price = results[0]?.selling_price;
        } else {
          price = 0;
        }
      }

      document.getElementById("product_price").innerHTML = numberToCurrency(
        exchangeCurrency(),
        exchangePrice(price)
      );
    } catch {
      return
    }
  }
}