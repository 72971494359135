import { Controller } from 'stimulus'
import { convertPrices } from '../../helpers/convert_price'
import feather from 'feather-icons'

export default class extends Controller {
  static targets = ['itemsWrapper']

  onSearch(event) {
    let [data, status, xhr] = event.detail
    $(this.itemsWrapperTarget).html(xhr.response)
    feather.replace()
    convertPrices()
  }
}
