import { Controller } from "stimulus";

export default class extends Controller {
  authModal({ detail }) {
    const [data] = detail;
    const el = document.getElementById("auth-modal");
    this._showModal(el, data);
  }

  openModal({ detail }) {
    const [data] = detail;
    const el = document.getElementById("modal-content");
    this._showModal(el, data);
  }

  _showModal(element, data) {
    element.innerHTML = data.content;
    $(element.childNodes).modal("show");
  }
}
