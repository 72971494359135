import { numberToCurrencyWithoutSymbol, numberToCurrency } from './currency'

export function convertPrice(price) {
  const currency = _getSelectedCurrency().currency
  const exchange = exchangePrice(price)
  const priceWithCurrency = currency === 'AUD' ? `${currency} ${numberToCurrencyWithoutSymbol(exchange.toFixed(2))}` : numberToCurrency(currency, exchange)
  return priceWithCurrency
}

export function convertPrices() {
  const currency = _getSelectedCurrency().currency

  const targets = document.getElementsByClassName('converted-price')
  targets.forEach(element => {
    if (element.dataset?.priceTag == undefined) {
      element.innerHTML = "-"
    } else {
      const price = exchangePrice(element.dataset.priceTag)
      const priceWithCurrency = currency === 'AUD' ? `${currency} ${numberToCurrencyWithoutSymbol(price.toFixed(2))}` : numberToCurrency(currency, price)
      element.innerHTML = priceWithCurrency
    }
  })
}

export function exchangePrice(price) {
  const rate = _getSelectedCurrency().rate
  return parseFloat(price)/parseFloat(rate)
}

export function exchangeCurrency() {
  return _getSelectedCurrency().currency
}

function _getSelectedCurrency() {
  return JSON.parse(localStorage.selected_currency)
}
