import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["product"]

  connect() {
    const value = this.productTarget.value
    if (this.products.includes(value)) {
      this.productTarget.checked = true
    }
  }

  addGroup(event) {
    let group
    const value = event.currentTarget.value
    const groupBtnController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller="merchant--group-btn"]'),
      "merchant--group-btn"
    )

    if (event.currentTarget.checked) {
      group = [...this.products, value]
    } else {
      group = this.products.filter((product) => product !== value)
    }

    localStorage.setItem("grouped-product", JSON.stringify(group))
    groupBtnController.toggleBtn()
  }

  get products() {
    return JSON.parse(localStorage.getItem("grouped-product")) || []
  }
}
