import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["shipmentTable", "packageList", "packageField", "packageFieldTemplate"];

  handleChangeCountryCode({ currentTarget }) {
    const query = `select[name="${currentTarget.name}"]`;
    this._changeAllValue(query, currentTarget.value);
  }

  handleChangeCity({ currentTarget }) {
    const query = `input[name="${currentTarget.name}"]`;
    this._changeAllValue(query, currentTarget.value);
  }

  handleChangeZipCode({ currentTarget }) {
    const query = `input[name="${currentTarget.name}"]`;
    this._changeAllValue(query, currentTarget.value);
  }

  handleChangePackage({ currentTarget }) {
    const parent = currentTarget.closest("[data-shippings--calculation-target='packageField']");
    const index = parent.dataset.packageIndex;
    const query = `input[name="${currentTarget.name}"]`;
    const packages = document.querySelectorAll(`.packages-${index}`);
    const volumetric = this._calculateVolumetric(parent);

    packages.forEach((pkg) => this._changeAllValue(query, currentTarget.value, pkg, volumetric));
  }

  shipmentResults({ detail }) {
    const [data] = detail;

    if (data.data) {
      data.data.forEach((result) => {
        const el = document.querySelector(`#shipment-rates-${result.tag}`);
        if (el !== null) el.innerHTML = result.content;
      });
    } else {
      this.shipmentTableTargets.forEach((el) => (el.innerHTML = ""));
    }
  }

  addPackages() {
    const length = this.packageFieldTargets.length;
    const template = this.packageFieldTemplateTarget.innerHTML.replace(/ID/g, length + 1);

    this.packageListTargets.forEach((el) => el.insertAdjacentHTML("beforeend", template));
  }

  destroyPackage({ currentTarget }) {
    const parent = currentTarget.closest("[data-shippings--calculation-target='packageField']");
    const index = parent.dataset.packageIndex;
    const elements = document.querySelectorAll(`.packages-${index}`);

    elements.forEach((el) => el.remove());
  }

  // private

  _changeAllValue(query, value, elements = document, volumetric = 0) {
    const volumetricFields = elements.querySelectorAll(".volumetric");
    const fields = elements.querySelectorAll(query);

    fields.forEach((field) => (field.value = value));

    // Don't change volumetric unless it's intended.
    if (volumetric !== 0) {
      volumetricFields.forEach((field) => (field.innerHTML = volumetric));
    }
  }

  _calculateVolumetric(parent) {
    const volumeFields = parent.querySelectorAll("[data-shippings--calculation-target='volumes']");
    const whl = Array.from(volumeFields).map((el) => parseFloat(el.value));
    const volumetric = whl.reduce((a, b) => a * b, 1) / 5000;

    return volumetric.toFixed(2)
  }
}
