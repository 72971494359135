export default function (form, modelName, errors) {
  _resetError()

  $.each(errors, function (field, messages) {
    const $field = form.find(`#${modelName}_${field.replace(/\.|[0-9]/g, "")}`)

    $field.addClass('is-invalid')
    $field.parent().append(`<small class='text-danger'>${messages.pop()}</small>`)
  })
}

// private
function _resetError() {
  $('select').removeClass('is-invalid')
  $('input').removeClass('is-invalid')
  $('textarea').removeClass('is-invalid')
  $('.text-danger').remove()
}