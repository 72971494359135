//
// tooltip.js
//

'use strict';

// $(function() {
//   $('[data-toggle="tooltip"]').tooltip();
// });

document.addEventListener('turbolinks:load', () => {
  $('[data-toggle="tooltip"]').tooltip();
})